import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, firstValueFrom, Observable, switchMap } from 'rxjs';
import { OverviewTotals } from '../interfaces/overview-totals.interface';
import { IBusinessCaseGeneration } from '../models/business-case-generation';
import { BusinessCaseSetup } from '../interfaces/business-case-setup.interface';
import { UpdateBusinessCasePayload } from '../interfaces/update-business-case-payload.interface';
import { IBusinessCaseTableFilter } from '../interfaces/business-case-table-filter.interface';
import { IBusinessCaseDetails } from '../interfaces/business-case-details.interface';
import { IBusinessCaseAccount } from '../interfaces/collaborator.interface';
import { IBusinessCaseOverview } from '../interfaces/business-case-overview.interface';
import { IActivityLog } from '../interfaces/activity-log.interface';
import { IBusinessCaseStageConfig } from '../interfaces/business-case-stage-config.interface';
import { format, startOfMonth, subDays } from 'date-fns';
import { BusinessCaseReviewPayload } from '../interfaces/business-case-review-payload.interface';
import { IBusinessCaseSoftServiceCostOverview } from '../models/business-case-service-cost-overview.interface';
import { IBusinessCaseSoftServiceQuote } from '../models/business-case-soft-service-quote';

@Injectable({
  providedIn: 'root'
})
export class BusinessCaseService {
  public businessCaseSetup!: BusinessCaseSetup;
  public defaultFilter: IBusinessCaseTableFilter = {
    currentPage: 1,
    size: 200,
    sortBy: 'initiationDate',
    sortDirection: 'Desc',
    startDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'), // yyyy-MM-dd or null to ignore
    endDate: format(new Date(), 'yyyy-MM-dd'), // yyyy-MM-dd or null to ignore
    filters: {
      overdue: false,
      delayed: false,
      businessCaseStatus: null,
      stageName: ''
    },
    searchTerm: '' // searches business case name
  };
  public businessCaseTableFilter: BehaviorSubject<IBusinessCaseTableFilter> =
    new BehaviorSubject(this.defaultFilter);

  public businessCaseTableFilter$: Observable<IBusinessCaseTableFilter> =
    this.businessCaseTableFilter.asObservable();

  private baseUrl: string = environment.apiUrl;
  private readonly _http: HttpClient = inject(HttpClient);

  public async initializeBusinessCase(businessCaseType: string): Promise<void> {
    await firstValueFrom(
      this._http.post<BusinessCaseSetup>(`${this.baseUrl}/business-cases`, {
        businessCaseType
      })
    ).then((res) => {
      this.businessCaseSetup = res;
    });
  }

  public generateBusinessCaseTable(): Observable<IBusinessCaseGeneration> {
    return this.businessCaseTableFilter$.pipe(
      switchMap((filterData) =>
        this._http.put<IBusinessCaseGeneration>(
          `${this.baseUrl}/business-cases/table`,
          filterData
        )
      )
    );
  }

  public fetchBusinessCaseById(id: number): Observable<IBusinessCaseDetails> {
    return this._http.get<IBusinessCaseDetails>(
      `${this.baseUrl}/business-cases/${id}`
    );
  }

  public fetchOverviewTotals(): Observable<OverviewTotals> {
    return this.businessCaseTableFilter$.pipe(
      switchMap((filterData) =>
        this._http.get<OverviewTotals>(
          `${this.baseUrl}/business-cases/overview/${filterData.startDate}/${filterData.endDate}`
        )
      )
    );
  }

  public updateBusinessCase(
    payload: UpdateBusinessCasePayload
  ): Observable<void> {
    return this._http.put<void>(`${this.baseUrl}/business-cases`, payload);
  }

  public fetchBusinessCaseAccounts(): Observable<Array<IBusinessCaseAccount>> {
    return this._http.get<Array<IBusinessCaseAccount>>(
      `${this.baseUrl}/business-cases/users`
    );
  }

  public fetchBusinessCaseOverview(
    id: number
  ): Observable<IBusinessCaseOverview> {
    return this._http.get<IBusinessCaseOverview>(
      `${this.baseUrl}/business-cases/${id}/overview`
    );
  }

  public fetchActivityLogs(id: number): Observable<Array<IActivityLog>> {
    return this._http.get<Array<IActivityLog>>(
      `${this.baseUrl}/business-cases/${id}/activities`
    );
  }

  public addNewComment(payload: {
    id: number;
    comment: string;
  }): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/business-cases/comments`,
      payload
    );
  }

  public fetchBusinessCaseSoftService(
    businessCaseId: number
  ): Promise<IBusinessCaseSoftServiceCostOverview> {
    return firstValueFrom(
      this._http.get<IBusinessCaseSoftServiceCostOverview>(
        `${this.baseUrl}/business-cases/${businessCaseId}/overview/soft-services`
      )
    );
  }

  public fetchBusinessCaseStagesConfigs(
    businessCaseId: number
  ): Observable<Array<IBusinessCaseStageConfig>> {
    return this._http.get<Array<IBusinessCaseStageConfig>>(
      `${this.baseUrl}/business-cases/${businessCaseId}/stages`
    );
  }

  public submitBusinessCaseForReview(payload: {
    businessCaseId: number;
    businessCaseStageId: number;
  }): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/business-cases/status/review`,
      payload
    );
  }

  public deleteBusinessCase(businessCaseId: number): Observable<void> {
    return this._http.delete<void>(
      `${this.baseUrl}/business-cases/${businessCaseId}`
    );
  }

  public rejectBusinessCase(
    payload: BusinessCaseReviewPayload
  ): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/business-cases/review`,
      payload
    );
  }

  public approveBusinessCase(
    payload: BusinessCaseReviewPayload
  ): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/business-cases/review`,
      payload
    );
  }

  public fetchBusinessCaseSoftServiceQuote(
    businessCaseId: number
  ): Observable<IBusinessCaseSoftServiceQuote> {
    return this._http.get<IBusinessCaseSoftServiceQuote>(
      `${this.baseUrl}/business-cases/${businessCaseId}/soft-services/quote`
    );
  }

  public resolveBCDelay(payload: FormData): Observable<any> {
    return this._http.put(
      `${this.baseUrl}/business-cases/delayed/reason`,
      payload
    );
  }

  public downloadBCDocument(docUrl: string): Observable<any> {
    return this._http.get(`${this.baseUrl}/${docUrl}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
