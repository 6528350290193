import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output
} from '@angular/core';

@Directive({
  selector: '[bidvestDragAndDrop]',
  standalone: true
})
export class DragAndDropDirective {
  @Output() public readonly fileDropped: EventEmitter<null> =
    new EventEmitter<null>();
  @HostBinding('style.opacity') private workspace_opacity: string = '1';

  // Dragover listener, when files are dragged over our host element
  @HostListener('dragover', ['$event']) public onDragOver(
    event: DragEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.workspace_opacity = '0.5';
  }

  // Dragleave listener, when files are dragged away from our host element
  @HostListener('dragleave', ['$event']) public onDragLeave(
    event: DragEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.workspace_opacity = '1';
  }

  //Drop listener, when files are dropped on our host element
  @HostListener('drop', ['$event']) public ondrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.workspace_opacity = '1';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const files: FileList | any = event.dataTransfer
      ? event.dataTransfer.files
      : [];
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
