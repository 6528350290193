import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { IBusinessCaseStageConfig } from 'libs/bidvest-ui/features/src/lib/features/interfaces/business-case-stage-config.interface';
import { BusinessCaseService } from 'libs/bidvest-ui/features/src/lib/features/services/business-case.service';

export const BusinessCaseConfigResolver: ResolveFn<
  Array<IBusinessCaseStageConfig>
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(BusinessCaseService).fetchBusinessCaseStagesConfigs(
    route.paramMap.get('id') as unknown as number
  );
};
