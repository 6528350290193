import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private readonly apiUrl: string = environment.apiUrl;

  public constructor(private readonly _http: HttpClient) {}

  public uploadFile(
    file: FormData,
    url: string,
    businessCaseId: number
  ): Observable<HttpEvent<null>> {
    return this._http.post<null>(
      `${this.apiUrl}/business-cases/${businessCaseId}/${url}`,
      file,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  public updateFile(
    file: FormData,
    url: string,
    businessCaseId: number
  ): Observable<HttpEvent<null>> {
    return this._http.put<null>(
      `${this.apiUrl}/business-cases/${businessCaseId}/${url}`,
      file,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }
}
