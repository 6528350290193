import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { LanguageService } from '../../services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bidvest-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  public languageSelector: FormGroup;
  public selectedOption: string = 'en';
  public langChangeSubscription: Subscription;
  public constructor(
    private readonly _languageService: LanguageService,
    private readonly _fb: FormBuilder,
    private readonly _router: Router,
    private readonly _translate: TranslateService
  ) {
    this.languageSelector = this._fb.group({
      lang: ['en']
    });
    this.langChangeSubscription = this._translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.languageSelector.patchValue({ lang: event.lang });
      }
    );
  }

  public ngOnInit(): void {
    const lang: string = this._languageService.getLanguage();
    this.languageSelector.get('lang')?.patchValue(lang);
  }

  public changeLanguage(): void {
    this._languageService.setLanguage(this.languageSelector.get('lang')?.value);
  }

  public goto(): void {
    void this._router.navigate(['roadmap']);
  }
}
