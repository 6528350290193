<div
  class="rounded p-6 bg-white flex flex-row justify-between items-center w-full mb-4"
>
  <span class="flex flex-row justify-start">
    <ng-content select="business-case-header-back-btn"></ng-content>

    <div class="flex flex-col justify-start ml-2">
      <div class="w-96">
        <h2 class="font-bold text-lg truncate">
          <ng-content select="business-case-header-title"></ng-content>
        </h2>
      </div>
      <span class="flex flex-row items-center">
        <button
          (click)="copyOnClick()"
          mat-button
          title="reference-number"
          class="flex flex-row items-center"
        >
          <span class="order-first">
            <ng-content select="business-case-header-number"></ng-content>
          </span>
          <mat-icon class="order-last text-sm">content_copy</mat-icon>
        </button>
      </span>
    </div>
  </span>

  <div class="flex flex-row justify-start">
    <ng-content select="business-case-header-actions"></ng-content>
  </div>
</div>
