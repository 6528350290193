import { Pipe, PipeTransform } from '@angular/core';

import { orderBy } from 'lodash';
@Pipe({
  name: 'orderBy',
  standalone: true
})
export class OrderByPipe implements PipeTransform {
  transform(value: any, sortBy: string, order?: 'asc' | 'desc'): any {
    const sortOrder: 'asc' | 'desc' = order ? order : 'asc';

    return orderBy(value, [sortBy], [sortOrder]);
  }
}
