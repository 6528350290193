import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastrService } from 'ngx-toastr';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'bidvest-business-case-header',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './business-case-header.component.html',
  styleUrl: './business-case-header.component.scss'
})
export class BusinessCaseHeaderComponent {
  @ContentChild('refNo')
  referenceNumber!: ElementRef<HTMLElement>;

  private _clipboard: Clipboard = inject(Clipboard);
  private _toastrService: ToastrService = inject(ToastrService);

  public copyOnClick(): void {
    const projectedRefNo: string = this.referenceNumber.nativeElement.innerHTML;
    this._clipboard.copy(projectedRefNo);
    this._toastrService.success(
      'Reference number has been successfully copied!'
    );
  }
}
