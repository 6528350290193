import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { IEmailTooltipComponentData } from '../../models/email-component-data';

@Component({
  selector: 'bidvest-email-tooltip',
  standalone: true,
  imports: [CommonModule, MatChipsModule],
  templateUrl: './email-tooltip.component.html',
  styleUrl: './email-tooltip.component.scss'
})
export class EmailTooltipComponent {
  @Input() public data: IEmailTooltipComponentData = {
    email: '',
    name: '',
    status: '',
    statusImg: ''
  };
}
