import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ILoggedInUser } from '../models';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public static NAME: string = 'bidvest-token';
  public static USER: string = 'bidvest-token-user';
  public static TOKEN: string | null;
  public token!: string | null;

  public constructor(public jwtHelper: JwtHelperService) {
    this.token = TokenService.TOKEN = this.getToken();
  }

  public saveToken(token: string): void {
    this.token = token;
    sessionStorage.setItem(TokenService.NAME, token);
  }

  public saveUser(user: ILoggedInUser): void {
    sessionStorage.setItem(TokenService.USER, JSON.stringify(user));
  }

  public getToken(): string | null {
    return sessionStorage.getItem(TokenService.NAME);
  }

  public getUser(): ILoggedInUser {
    const user: string | null = sessionStorage.getItem(TokenService.USER);
    return JSON.parse(user ?? '');
  }

  public clearToken(): void {
    sessionStorage.clear();
  }

  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.token);
  }
}
